// Modules exported here will be included in bundle.js

//interactive result action function
export function insertResultAction(resultAction, eventType, value, title) {
  var message = {"eventType": eventType, "value": value, "title": title};
  parent.postMessage(message, "*");

  var optionText = resultAction.getElementsByClassName("resultAction-insert-option")[0];
  optionText.classList.add('not-visible');
  optionText.classList.remove('made-visible');

  var insertedText = resultAction.getElementsByClassName("resultAction-inserted-message")[0];
  insertedText.classList.add('made-visible');

  setTimeout(function() { 
    optionText.classList.add('made-visible');
    optionText.classList.remove('not-visible');
    insertedText.classList.remove('made-visible');
    }, 2000);
};



//copy to clipboard result action
export function showCopiedMessage(resultAction, copyData) {
  const clipboardData = {
    'text/html': new Blob([copyData], {type: 'text/html'}),
  };

  clipboardData['text/plain'] = new Blob([convertToPlainText(copyData)], {type: 'text/plain'});

  const clipboardItem = new ClipboardItem(clipboardData);
  navigator.clipboard.write([clipboardItem]);

  var optionText = resultAction.getElementsByClassName("clipboard-copy-option")[0];
  optionText.classList.add('not-visible');
  optionText.classList.remove('made-visible');

  var copiedText = resultAction.getElementsByClassName("clipboard-copied-message")[0];
  copiedText.classList.add('made-visible');

  setTimeout(function() {
    optionText.classList.add('made-visible');
    optionText.classList.remove('not-visible');
    copiedText.classList.remove('made-visible');
  }, 2000);
};

function convertToPlainText(html){
  let doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || "";
}